<!-------------------------------------------------
description:  运维中心 告警中心 告警信息列表
/**
 *@author ocean
 *@date 2024/2/28
*/
--------------------------------------------------->
<template>
  <div class="page-container">
    <custom-list
      table-title="告警信息列表"
      :show-header="true"
      ref="customList"
      :search-data="searchData"
      :search-params="searchParams"
      :table-columns="tableColumns"
      :table-operate-buttons="tableOperateButtons"
      :header-buttons="headerButtons.filter(item => item.enabled())"
      :request-method="omManagementApi.alarmInfoQueryPage"
      :operateFixedType="'right'"
      :operateButtons="tableOperateButtons.filter(item => item != '{null}')"
      @query-form="queryForm"
      @clear-form="clearForm" />
    <!-- 新增/编辑抽屉 -->
    <ykc-drawer
      title="报警转维修"
      :show.sync="showDrawer"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <AddOrEdit ref="addOrEdit" :editInfo="editInfo"></AddOrEdit>
      </div>
    </ykc-drawer>
  </div>
</template>

<script>
  import { omManagementApi, barrierPage, brandModelPage } from '@/service/apis';
  import { code, getLastTime, offlineExport } from '@/utils';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { loadDicItem } from '@/utils/dictionary';
  import CustomList from '@/components/biz/CustomList.vue';
  import AddOrEdit from './AddOrEdit.vue';

  export default {
    name: 'OMAlarmsInfoList',
    components: { CustomList, AddOrEdit },
    data() {
      return {
        editInfo: {},
        showRuleConfigDialog: false,
        showSMSNotificationRuleDialog: false,
        dictionaryAlarmReason: [], // 告警事项组件使用数据源
        dictionaryAlarmLevel: [], // 告警等级组件使用数据源
        dictionaryAlarmType: [], // 告警分类组件使用数据源
        brandAndModelData: [], // 电桩型号数据
        stationIdData: [], // 归属电站组件使用数据源
        smsInfoRulesConfigs: [], //  短信通知规则配置
        drawerType: 'add',
        showDrawer: false,
        searchParams: {
          date: [],
        },
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                showTitle: false,
                showFooter: true,
                dialogType: 'feedback',
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },

          {
            label: '保存',
            enabled: () => {
              return true;
            },

            handler: () => {
              this.$refs.addOrEdit.submitForm().then(() => {
                this.$refs.customList.searchTableList();
                this.showDrawer = false;
              });
            },
          },
        ],
        tableColumns: [
          {
            label: '告警时间',
            prop: 'alarmTime',
            width: 200,
            scopedSlots: { defaultTitle: () => '' },
          },
          {
            label: '电站名称',
            prop: 'stationName',
            width: 150,
            scopedSlots: { defaultTitle: () => '' },
          },
          {
            label: '终端编码',
            prop: 'gunCode',
            width: 200,
            // scopedSlots: {
            //   defaultTitle: () => '',
            //   default: ({ row }) => {
            //     return (
            //       <el-tooltip effect="dark" content={row.gunCode} placement="top-start">
            //         <span>{row.gunCode}</span>
            //       </el-tooltip>
            //     );
            //   },
            // },
          },
          { label: '告警事项', width: 150, prop: 'alarmReason' },
          { label: '告警等级', width: 100, prop: 'alarmLevelMsg' },
          {
            label: '订单号',
            prop: 'tradeSeq',
            width: 280,
            scopedSlots: {
              defaultTitle: () => '',
              default: ({ row }) => {
                return (
                  <el-tooltip effect="dark" content={row.tradeSeq} placement="top-start">
                    <span>{row.tradeSeq}</span>
                  </el-tooltip>
                );
              },
            },
          },
          { label: '告警分类', width: 150, prop: 'alarmType' },
          { label: '电桩品牌', width: 150, prop: 'brand', scopedSlots: { defaultTitle: () => '' } },
          {
            label: '电桩型号',
            width: 150,
            prop: 'modelName',
            scopedSlots: { defaultTitle: () => '' },
          },
          {
            label: '是否通知处理',
            prop: 'noticeFlag',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                const { noticeFlag } = row;
                return <span>{(noticeFlag === 0 ? '否' : '是') || '——'}</span>;
              },
            },
          },
        ],
        tableOperateButtons: [
          {
            render: (h, data, { row }) => {
              if (row.noticeFlag) {
                if (row.change) {
                  return (
                    <ykc-button type="text" disabled>
                      转维修
                    </ykc-button>
                  );
                }
                return (
                  <ykc-button
                    type="text"
                    onClick={() => {
                      console.log(h, data, row);
                      this.editInfo = row;
                      this.showDrawer = true;
                    }}>
                    转维修
                  </ykc-button>
                );
              }
              return null;
            },

            // row.noticeFlag ? (
            //   <ykc-button
            //     type="text"
            //     onClick={() => {
            //       if (!row.change) return;
            //       console.log(h, data, row);
            //       this.editInfo = row;
            //       this.showDrawer = true;
            //     }}>
            //     转维修
            //   </ykc-button>
            // ) : null,
          },
        ],
      };
    },
    computed: {
      omManagementApi() {
        return omManagementApi;
      },
      headerButtons() {
        return [
          {
            enabled: () => code('maintain:alarm:export'),
            label: '导出',
            handle: (data, ctx) => {
              console.log('-> data', data);
              console.log('-> ctx', ctx);
              offlineExport(
                {
                  downloadType: 'alarm_info_export',
                  jsonNode: {
                    ...ctx.searchParams,
                  },
                },
                ctx.pageInfo.total
              );
            },
          },
        ];
      },
      searchData() {
        return [
          {
            key: 'date',
            label: '告警时间',
            comName: 'YkcDatePicker',
            placeholder: '请选择告警时间',
          },
          {
            multiple: false,
            label: '电站名称',
            key: 'stationId',
            comName: 'YkcDropdown',
            placeholder: '请选择电站名称',
            data: this.stationIdData,
            remote: true,
          },
          {
            comName: 'YkcDropdown',
            key: 'alarmReason',
            label: '告警事项',
            placeholder: '请选择告警事项',
            remote: true,
            data: [...this.dictionaryAlarmReason],
          },
          {
            comName: 'YkcInput',
            key: 'gunCode',
            label: '终端编码',
            placeholder: '请输入终端编码',
          },
          {
            comName: 'YkcDropdown',
            key: 'alarmLevel',
            label: '告警等级',
            placeholder: '请选择告警等级',
            data: [...this.dictionaryAlarmLevel],
          },
          {
            comName: 'YkcInput',
            key: 'tradeSeq',
            label: '订单号',
            placeholder: '请输入订单号',
          },
          {
            comName: 'YkcDropdown',
            key: 'alarmType',
            label: '告警分类',
            placeholder: '请选择告警分类',
            data: [...this.dictionaryAlarmType],
          },
          {
            comName: 'YkcInput',
            key: 'brand',
            label: '电桩品牌',
            placeholder: '请输入电桩品牌',
          },
          {
            remote: true,
            clearable: true,
            comName: 'YkcDropdown',
            key: 'modelId',
            label: '电桩型号',
            placeholder: '请选择电桩型号',
            data: this.brandAndModelData,
          },
        ];
      },
    },
    created() {
      this.dictionaryAlarmType = loadDicItem('alarm_type') || []; // 告警分类
      this.dictionaryAlarmLevel = loadDicItem('alarm_level') || []; // 告警等级

      // 近30天初始化时间
      const startDate = getLastTime('yyyy-MM-dd', 30);
      const endDate = getLastTime('yyyy-MM-dd', 0);

      this.searchParams.date = [startDate, endDate];
      this.searchParams.startTime = startDate;
      this.searchParams.endTime = endDate;

      this.dictionaryAlarmReason = this.mergeArrays(
        loadDicItem('alarm_reason_1'),
        loadDicItem('alarm_reason_2'),
        loadDicItem('alarm_reason_3'),
        loadDicItem('alarm_reason_4')
      ); // 告警事项
      this.requestStationIdDataList();
      this.requestBrandAndModelList();
    },
    methods: {
      /**
       * 获取电站数据
       */
      requestStationIdDataList() {
        barrierPage
          .findStationList({})
          .then(res => {
            console.log('获取电站数据+++++', res);
            res.map(item => {
              return this.stationIdData.push({
                id: item.stationId,
                name: item.stationName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 获取电桩型号列表
       */
      requestBrandAndModelList() {
        brandModelPage
          .brandModelList({ keyword: '' })
          .then(res => {
            console.log('获取电桩型号列表+++++', res);
            res.map(item => {
              return this.brandAndModelData.push({
                id: item.id,
                name: item.modelName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      queryForm(searchData) {
        // 时间选择
        if (searchData.date && Array.isArray(searchData.date) && searchData.date.length > 0) {
          [this.searchParams.startTime, this.searchParams.endTime] = searchData.date;
        }
      },
      /**
       * 合并多个数组
       */
      mergeArrays(...arrays) {
        // 使用reduce方法将所有数组合并为一个数组
        return arrays.reduce((mergedArray, currentArray) => {
          return mergedArray.concat(currentArray);
        }, []);
      },
      clearForm() {
        // 近30天初始化时间
        const startDate = getLastTime('yyyy-MM-dd', 30);
        const endDate = getLastTime('yyyy-MM-dd', 0);
        this.searchParams.date = [startDate, endDate];
        this.searchParams.startTime = startDate;
        this.searchParams.endTime = endDate;
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      onAddDrawerClose(done) {
        done();
      },
    },
  };
</script>

<style scoped>
  .lable-red {
    color: #f56c6c;
  }
  .lable-orange {
    color: #e6a23c;
  }
  .lable-green {
    color: #67c23a;
  }
</style>
