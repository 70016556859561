<!-- 系统设置 权限管理 账号管理 新增或编辑或数据权限 -->
<template>
  <ykc-form ref="YkcForms" :model="ruleForm" :rules="rules">
    <ykc-form-item label="站点Id" prop="stationId" hidden>
      <ykc-Input hidden v-model="ruleForm.stationId" :disabled="true"></ykc-Input>
    </ykc-form-item>
    <ykc-form-item label="站点名" prop="stationId">
      <ykc-Input v-model="ruleForm.stationName" :disabled="true"></ykc-Input>
    </ykc-form-item>
    <ykc-form-item label="设备编码" prop="gunCode">
      <ykc-Input v-model="ruleForm.gunCode" :disabled="true"></ykc-Input>
    </ykc-form-item>
    <ykc-form-item label="处理说明" prop="remark">
      <ykc-input
        type="textarea"
        maxlength="500"
        v-model="ruleForm.remark"
        placeholder="请输入处理说明"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="故障信息" prop="failureInfo">
      <ykc-input
        type="textarea"
        maxlength="500"
        v-model="ruleForm.failureInfo"
        placeholder="请输入故障信息"></ykc-input>
    </ykc-form-item>

    <ykc-form-item label="期望维修日期" prop="workDate">
      <ykc-date-picker
        type="date"
        v-model="ruleForm.workDate"
        maxlength="30"
        placeholder="请选择期望维修日期"></ykc-date-picker>
    </ykc-form-item>
  </ykc-form>
</template>

<script>
  import { barrierPage, omManagementApi, marketManage } from '@/service/apis';

  export default {
    props: {
      editInfo: {
        type: Object,
        default: () => {
          return {};
        },
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        detail: {},
        stationIdData: [],
        pileData: [],

        ruleForm: {
          workDate: '',
          stationId: '',
          stationName: '',
          gunCode: '',
          remark: '',
          failureInfo: '',
        },
        rules: {
          stationId: [{ required: false, message: '请选择站点', trigger: 'blur' }],
          gunCode: [{ required: false, message: '请选择设备编码', trigger: 'blur' }],
          // failureInfo: [{ required: true, message: '请输入故障信息', trigger: 'blur' }],
          // remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
          // workDate: [{ required: true, message: '请选择期望维修日期', trigger: 'blur' }],
        },
      };
    },
    created() {
      console.log('===', this.editInfo);
      this.ruleForm.stationName = this.editInfo.stationName;
      this.ruleForm.gunCode = this.editInfo.gunCode;
      // this.doFetchOrgInfos(); // 获取归属电站列表
    },
    computed: {
      formData() {
        const formData = {
          alarmId: this.editInfo.id,
          workDate: this.ruleForm.workDate,
          stationId: this.editInfo.stationId,
          stationName: this.editInfo.stationName,
          gunCode: this.editInfo.gunCode,
          remark: this.ruleForm.remark,
          failureInfo: this.ruleForm.failureInfo,
          failureTime: this.editInfo.alarmTime,
          change: 1,
        };
        return formData;
      },
    },
    methods: {
      /**
       * @desc 获取归属列表
       * */
      doFetchOrgInfos() {
        barrierPage
          .findStationList({})
          .then(res => {
            res.map(item => {
              return this.stationIdData.push({
                id: item.stationId,
                name: item.stationName,
              });
            });
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * @desc 获取设备编码
       * */
      doFindStationAndPile() {
        marketManage
          .findStationPile({
            stationId: this.ruleForm?.stationId,
          })
          .then(res => {
            res.forEach(item => {
              this.pileData.push({
                id: item.pileCode,
                name: item.pileCode,
              });
            });
          });
      },

      /**
       * 获取电站
       */
      selectChange(e) {
        this.ruleForm.stationId = e;
        this.doFindStationAndPile(); // 获取终端列表
      },

      /**
       * 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件
       */
      submitForm() {
        console.log('this.ruleForm++++', this.ruleForm);
        return new Promise(resolve => {
          this.validateForm().then(() => {
            console.log('编辑', this.formData);
            omManagementApi
              .operationAdd(this.formData)
              .then(res => {
                console.log(res);
                this.$message({
                  message: '保存成功',
                  type: 'success',
                });
                resolve();
              })
              .catch(() => {});
          });
        });
      },
      /**
       *
       */
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs.YkcForms.validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .drawer-button-wrap {
    padding: 0 10px;
    button {
      margin-right: 5px;
    }
  }
</style>
